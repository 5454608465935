import config from '../config';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import Cookies from 'js-cookie';
import { decodeToken } from '../util/Token';
import '../styles/index.css';

Sentry.init({
  enabled: config.env === 'production' || config.env === 'mock',
  dsn: config.sentryDsn,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured with value',
    'Object Not Found Matching Id',
  ],
  integrations: [
    new RewriteFrames({
      iteratee: (frame) => {
        frame.filename = frame.filename.replace(
          `${getConfig().serverRuntimeConfig.rootDir}/.next`,
          'app:///_next'
        );
        return frame;
      },
    }),
  ],
  beforeSend(event) {
    event.tags = {
      commitSha: config.commitSha,
    };
    event.user = decodeToken(Cookies.get('token'));
    return event;
  },
});

function App({ Component, pageProps, err }) {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return <Component {...pageProps} err={err} />;
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object,
};

export default App;
